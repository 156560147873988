.wizard {
  border: solid 1px lightgray;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0px lightgray;
  margin-top: 1em;
  padding: 1em;
}

.wizard__robot-container {
  margin: 2em 0;
  text-align: center;
}

.wizard__robot {
  height: 12em;
  margin: 0 auto;
}

.wizard__title {
  font-size: 1.4em;
}

.wizard__subtitle {
  font-size: 1.2em;
}

.wizard__header {
  text-align: right;
}

.wizard__body {
  min-height: 20em;
}

.wizard__footer {
  display: flex;
  direction: rtl;
  justify-content: space-between;
  margin-top: 1em;
}

@media (prefers-reduced-motion: no-preference) {
  .wizard__robot {
    animation: Robot-spin infinite 2.5s ease-in-out;
    animation-delay: 2s;
  }
}

@keyframes Robot-spin {
  30% {
    transform: translate(-2px, 5px);
  }
  70% {
    transform: translate(3px, -4px);
  }
}
