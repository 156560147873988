.intent {
  border: solid 1px rgb(93, 90, 220);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 0.9em;
  margin-bottom: 1em;
  min-height: 4em;
  line-height: 1.2em;
  width: 98%;
  padding: 0.5em;
}

.intent p {
  margin-bottom: 0.5em;
}

.intent--selected {
  border: solid 3px rgb(93, 90, 220);
}

.intent__title {
  font-size: 1em;
}

.btn.intent__see-more {
  padding-left: 0;
  padding-right: 0;
}
